import { NavigateFunction, Location } from "react-router-dom";
import queryString from "query-string";
import { SkuOptionModel, SkuOptionProps } from "./SkuOptions.componentModel";
import { useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Option, OptionGroup, Sku } from "../../../../interface/Product";

export const useComponentModel = (props: SkuOptionProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const componentData = useMemo(() => new SkuOptionModel(props), [props]);
  const componetService = useMemo(
    () =>
      new optionGroupService({
        props,
        navigate,
        location,
      }),
    [props, navigate, location],
  );

  const setSelectionFromUrlParamsRef = useRef(componetService.setSelectionFromUrlParams);
  setSelectionFromUrlParamsRef.current = componetService.setSelectionFromUrlParams;
  useEffect(() => {
    if (props.config.defaultSkuOnPageLoad) setSelectionFromUrlParamsRef.current();
  }, [props.config.defaultSkuOnPageLoad]);

  return { componentData, componetService };
};

class optionGroupService {
  private props;

  private navigate: NavigateFunction = () => {};
  private location: Location = {} as Location;

  constructor({
    props,
    navigate,
    location,
  }: {
    props: SkuOptionProps;
    navigate: NavigateFunction;
    location: Location;
  }) {
    this.props = props;

    this.navigate = navigate;
    this.location = location;
  }

  setActiveFilter(newSelection: SkuOptionProps["selection"]) {
    this.props.setSelection(newSelection);
    if (!this.props.disableQs) {
      this.navigate(
        {
          pathname: this.location.pathname,
          search: queryString.stringify(newSelection, { arrayFormat: "comma" }),
        },
        { replace: true },
      );
    }
  }

  resetFilters() {
    this.setActiveFilter({});
  }

  removeFilter(optionGroup: OptionGroup) {
    const { selection } = this.props;
    const newSelection = { ...selection };
    delete newSelection[optionGroup.optionGroupCode];
    this.setActiveFilter(newSelection);
  }

  updateFilter(optionGroup: OptionGroup, option: Option, filteredProducts: SkuOptionModel["filteredProducts"]) {
    const { selection } = this.props;
    if (
      filteredProducts.find(({ options }) =>
        options.find((filteredOption) => filteredOption.optionID === option.optionID),
      )
    ) {
      const newSelection = { ...selection, [optionGroup.optionGroupCode]: option.optionCode };
      this.setActiveFilter(newSelection);
    } else {
      this.setActiveFilter({ [optionGroup.optionGroupCode]: option.optionCode });
    }
  }

  setSelectionFromUrlParams = () => {
    const { selection, product } = this.props;
    const params = queryString.parse(this.location.search, {
      arrayFormat: "separator",
      arrayFormatSeparator: ",",
    }) as SkuOptionProps["selection"];

    if (
      Object.keys(params).length &&
      Object.keys(selection).length &&
      Object.entries(params).every(([key, value]) => selection[key] === value)
    ) {
      return;
    }

    // Directly use url params as selection if skuID param not exist
    if (Object.keys(params).length > 0 && !params?.skuid) {
      this.setActiveFilter(params);
      return;
    }

    if (product.optionGroups?.length) {
      // Fallback to default skuID if skuID param not exist
      const skuID = params?.skuid || product.defaultSku_skuID;
      const sku: Sku = product.skus.find((sku: Sku) => sku.skuID === skuID) || product.skus.at(0);
      if (sku) {
        const defaultSelection = sku.options.reduce<{ [key: string]: string }>((acc, cur) => {
          acc[cur.optionGroupCode] = cur.optionCode;
          return acc;
        }, {});
        this.setActiveFilter(defaultSelection);
      }
    }
  };
}
